<template>
  <div>
    <b-table
      bordered
      hover
      v-bind:sticky-header="tableHeight"
      v-bind:items="coins"
      v-bind:fields="fields"
      headVariant="light"
      show-empty
    >
      <template v-slot:cell(image)="data">
        <div class="token-icon" v-bind:style="setIcon(data.value)" />
      </template>

      <template v-slot:cell(send)="data">
        <b-button
          size="sm"
          variant="primary"
          class="mr-2"
          v-on:click="showTransferModal(data.item)"
        >
          Send
        </b-button>
      </template>

      <template v-slot:empty>
        <b-spinner variant="primary" />
      </template>
    </b-table>
    <b-modal
      v-bind:id="transferModalID"
      hide-footer
      v-bind:title="`Send ${selectedItem.symbol}`"
    >
      <TheWalletAssetsSendForm
        v-bind:token-id="selectedItem.tokenId"
        v-bind:decimals="selectedItem.decimals"
        v-bind:protocol="selectedItem.protocol"
        v-on:success="handleSuccessfulTransfer"
      />
    </b-modal>
  </div>
</template>

<script>
import TheWalletAssetsSendForm from "@/components/wallet/TheWalletAssetsSendForm";
import { WALLET_BALANCE_RETRIEVE_ROUTE } from "@/constants/relative_api_routes";

export default {
  name: "TheWalletBalanceList",
  components: { TheWalletAssetsSendForm },
  props: {
    tableHeight: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "image", label: "Icon" },
        "symbol",
        { key: "amount", label: "Balance" },
        "send",
      ],
      transferModalID: "transfer-modal",
      selectedItem: {},
      coins: [],
    };
  },
  methods: {
    showTransferModal(item) {
      this.selectedItem = item;
      this.$bvModal.show(this.transferModalID);
    },
    setIcon(iconUrl) {
      return {
        "background-image": `url(${iconUrl})`,
      };
    },
    processCoinList(coins) {
      return coins.map((coin) => {
        if (!coin.tokenId) {
          coin.image = require(`@/assets/images/bsv_icon.png`);
          coin.symbol = coin.protocol;
          coin.amount = (coin.balance / 10 ** 8).toFixed(8);
        } else {
          coin.amount = coin.amount || 0;
          coin.amount /= coin.satsPerToken;
          if (coin.decimals) {
            coin.amount = (coin.amount / 10 ** coin.decimals).toFixed(2);
          }
        }
        return coin;
      });
    },
    getWalletBalance() {
      this.axios
        .get(this.appConfig.BACKEND_API_URL + WALLET_BALANCE_RETRIEVE_ROUTE)
        .then((response) => {
          this.coins = this.processCoinList(response.data.coins);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleSuccessfulTransfer() {
      this.$bvModal.hide(this.transferModalID);
      this.getWalletBalance();
    },
  },
  created() {
    this.getWalletBalance();
  },
};
</script>
